import React from 'react';
import Layout from '../../components/Layout';
import NavThree from '../../components/NavThree';
import PageHeader from '../../components/SchoolHeader';
import Footer from '../../components/Footer';
import ProgramsList from '../../components/ProgramsList';

const Midwifery = () => {
  return (
    <Layout pageTitle='School of Midwifery | LUTH'>
      <NavThree />
      <PageHeader title='School of Midwifery' />
      <ProgramsList name='School of Midwifery' />
      <Footer />
    </Layout>
  );
};

export default Midwifery;
